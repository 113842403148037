import React from 'react';
import AppContext from "../../contexts/AppContext";
import {mapSelectedImageItem, webviewAnalyticsEvent, webviewCall} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import routes from "../../routes";
import i18n from "../../i18n";
import {debounce} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import {imageStatus} from "./shared";
import {Button, Col, Container, Figure, Form, Row, Stack} from "react-bootstrap";
import * as api from "../../utils/api";
import {v4 as uuidv4} from "uuid";
import {generatePath} from "react-router";

const predefineds = [
  {
    seeds: 324,
    prompt: "A {gender} as the ASIA prince, beautiful plants and glitter bugs, 8k resolution concept art portrait by Greg Rutkowski, Artgerm, WLOP, Alphonse Mucha dynamic lighting hyperdetailed intricately detailed Splash art trending on Artstation triadic colors Unreal Engine 5 volumetric lighting ethereal elemental elegant BEAUTIFUL ASIA prince GOD, ivory amber and gunmetal, intricately detailed, fantasy, long flowing hair vibrant, hyperdetailed, detailed background, wet brush, wet canvas, stunning splash art by Ross Tran, Muyang Xu, and Guodong Zhao elaborate and intricate ink illustration of a cosy frost with amber sparks, solar winds made of liquid by James Jean, Ivan Bilibin, Peter Mohrbacher golden ratio, hypervivid geometric, fluid acrylic, elegant gradients, 3d liquid detailing, subsurface scattering, textured, intricate, dripping paint, 8k resolution octane render Vintage Photograph! Artgerm, WLOP, Greg Rutkowski; BEAUTIFUL ASIA prince GOD cosy ice, colourful shiny long hair, Intricately Designed beautiful face, Intricately Designed light rose and Crystal Bodice, Flowers!! Photograph Taken on Nikon D750, Intricate, sinister, Elegant, Scenic, Hyper-Detailed, 8k, rogue hourglass slim figure, featuring head to knees potrait, proportional body automatic correction, shows knee, hyper realism by gxin yi / douyin, daniel f gerhartz, volumetric lighting, elegant gradients, hyperrealistic, masterpiece, trending on artstation Epic cinematic brilliant stunning intricate meticulously detailed dramatic atmospheric maximalist digital matte painting",
  },
  {
    seeds: 55,
    prompt: "A {gender} dressed as hippie, hippie hairstyle, hippie ornaments, digital illustration, highly detailed, high resolution",
  },
  {
    seeds: 16,
    prompt: "A {gender} as rock star, wearing black biker jacket, bar with neon lights on background, high resolution",
  },
  {
    seeds: 15,
    prompt: "A {gender} with blonde short bob haircut, high resolution",
  },
  {
    seeds: 33,
    prompt: "A {gender} dressed in pink glamorous clothes, barbie hairstyle, glamour style, pink background, high resolution",
  },
  {
    seeds: 88,
    prompt: "A {gender} as the ASIA prince, beautiful plants and glitter bugs, 8k resolution concept art portrait by Greg Rutkowski, Artgerm, WLOP, Alphonse Mucha dynamic lighting hyperdetailed intricately detailed Splash art trending on Artstation triadic colors Unreal Engine 5 volumetric lighting ethereal elemental elegant BEAUTIFUL ASIA prince GOD, ivory amber and gunmetal, intricately detailed, fantasy, long flowing hair vibrant, hyperdetailed, detailed background, wet brush, wet canvas, stunning splash art by Ross Tran, Muyang Xu, and Guodong Zhao elaborate and intricate ink illustration of a cosy frost with amber sparks, solar winds made of liquid by James Jean, Ivan Bilibin, Peter Mohrbacher golden ratio, hypervivid geometric, fluid acrylic, elegant gradients, 3d liquid detailing, subsurface scattering, textured, intricate, dripping paint, 8k resolution octane render Vintage Photograph! Artgerm, WLOP, Greg Rutkowski; BEAUTIFUL ASIA prince GOD cosy ice, colourful shiny long hair, Intricately Designed beautiful face, Intricately Designed light rose and Crystal Bodice, Flowers!! Photograph Taken on Nikon D750, Intricate, sinister, Elegant, Scenic, Hyper-Detailed, 8k, rogue hourglass slim figure, featuring head to knees potrait, proportional body automatic correction, shows knee, hyper realism by gxin yi / douyin, daniel f gerhartz, volumetric lighting, elegant gradients, hyperrealistic, masterpiece, trending on artstation Epic cinematic brilliant stunning intricate meticulously detailed dramatic atmospheric maximalist digital matte painting",
  },
  {
    seeds: 56,
    prompt: "A {gender} with rococo hairstyle, wearing rococo style clothes, Jean-Honore Fragonard art style, nature on background, high resolution/ douyin, daniel f gerhartz, volumetric lighting, elegant gradients, hyperrealistic, masterpiece, trending on artstation Epic cinematic brilliant stunning intricate meticulously detailed dramatic atmospheric maximalist digital matte painting",
  },
  {
    seeds: 324,
    prompt: "A {gender} as the ASIA prince, beautiful plants and glitter bugs, 8k resolution concept art portrait by Greg Rutkowski, Artgerm, WLOP, Alphonse Mucha dynamic lighting hyperdetailed intricately detailed Splash art trending on Artstation triadic colors Unreal Engine 5 volumetric lighting ethereal elemental elegant BEAUTIFUL ASIA prince GOD, ivory amber and gunmetal, intricately detailed, fantasy, long flowing hair vibrant, hyperdetailed, detailed background, wet brush, wet canvas, stunning splash art by Ross Tran, Muyang Xu, and Guodong Zhao elaborate and intricate ink illustration of a cosy frost with amber sparks, solar winds made of liquid by James Jean, Ivan Bilibin, Peter Mohrbacher golden ratio, hypervivid geometric, fluid acrylic, elegant gradients, 3d liquid detailing, subsurface scattering, textured, intricate, dripping paint, 8k resolution octane render Vintage Photograph! Artgerm, WLOP, Greg Rutkowski; BEAUTIFUL ASIA prince GOD cosy ice, colourful shiny long hair, Intricately Designed beautiful face, Intricately Designed light rose and Crystal Bodice, Flowers!! Photograph Taken on Nikon D750, Intricate, sinister, Elegant, Scenic, Hyper-Detailed, 8k, rogue hourglass slim figure, featuring head to knees potrait, proportional body automatic correction, shows knee, hyper realism by gxin yi / douyin, daniel f gerhartz, volumetric lighting, elegant gradients, hyperrealistic, masterpiece, trending on artstation Epic cinematic brilliant stunning intricate meticulously detailed dramatic atmospheric maximalist digital matte painting",
  },
  {
    seeds: 179,
    prompt: "A {gender} with beautiful haircut, portrait painted in oil pink scene, everything is pink, perfect pink shading, pink atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws, oil painting style background/ douyin, daniel f gerhartz, volumetric lighting, elegant gradients, hyperrealistic, masterpiece, trending on artstation Epic cinematic brilliant stunning intricate meticulously detailed dramatic atmospheric maximalist digital matte painting",
  },
  {
    seeds: 240,
    prompt: "A {gender} looks like a cute cat portrait, cute cat ears, cute style, anime style, olpntng style, oil painting, heavy strokes, paint dripping, game art, hyper detailed, photorealistic, incredible composition",
  },
  {
    seeds: 38,
    prompt: "A {gender} portrait in pltn style, Cute and adorable fantasy bird, shiny feathers, glowing smoke neon eyes, fantasy, sunlight, sunbeam, intricate detail. 8k, dreamlike, surrealism, super cute, symmetrical, soft lighting, trending on artstation, intricate details, highly detailed, unreal engine, by ross tran, wlop, artgerm and james jean, Brian Froud, art illustration by Miho Hirano, Neimy Kanani, oil on canvas by Aykut Aydoğdu, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
  },
].map((item) => {
  item.id = uuidv4();
  return item;
});

const faceTemplateChains = [
  {
    value: "",
    label: "без препроцессинга",
  },
  {
    value: "7088",
    label: "7088 ImmaculARTly Complete",
  },
  {
    value: "6472,8666",
    label: "6472+8666 Life in Plastic",
  },
  {
    value: "6683",
    label: "6683 All to the Cute",
  },
  {
    value: "7626",
    label: "7626 Popart",
  },
  {
    value: "8682",
    label: "8682 Fiverr",
  },
];

export default class PhotochooserPage extends React.Component {

  state = {
    images: clientStorage.getLatestSelectedImages(),
    promptText: predefineds[0].prompt,
    negativePromptText: predefineds[0].negativePrompt || "(deformed, distorted, disfigured:1.3), poorly drawn, bad anatomy, wrong anatomy, extra limb, missing limb, floating limbs, (mutated hands and fingers:1.4), disconnected limbs, mutation, mutated, ugly, disgusting, blurry, amputation. tattoo",
    seeds: "" + predefineds[0].seeds,
    strengths: "0.4, 0.5, 0.6, 0.7, 0.8",
    predefinedId: predefineds[0].id,
    faceTemplateChain: faceTemplateChains[1].value,
    ctrlnetWeight: "",
    num_steps: "30",
  };

  fileFieldRef = React.createRef();
  imageTasks = [];
  imagesNumber = this.state.images.isNotEmpty()
    ? this.state.images.max((i) => i.number)
    : 0;

  constructor(props) {
    super(props);

    const loadedUrl = new URL(window.location.href);
    if (loadedUrl.searchParams.has("inputdata")) {
      try {
        const inputData = JSON.parse(loadedUrl.searchParams.get("inputdata"));

        if (inputData.seeds && inputData.seeds.length > 0) {
          this.state.seeds = Array.isArray(inputData.seeds) ? inputData.seeds.join(",") : inputData.seeds;
        }

        if (inputData.strengths && inputData.strengths.length > 0) {
          this.state.strengths = Array.isArray(inputData.strengths) ? inputData.strengths.join(",") : inputData.strengths;
        }

        if (inputData.prompt && inputData.prompt.length > 0) {
          this.state.promptText = inputData.prompt;
        }

        if (inputData.negativePrompt && inputData.negativePrompt.length > 0) {
          this.state.negativePromptText = inputData.negativePrompt;
        }

        if (inputData.faceTemplateChain) {
          this.state.faceTemplateChain = inputData.faceTemplateChain;
        }

        if (inputData.ctrlnetWeight) {
          this.state.ctrlnetWeight = inputData.ctrlnetWeight;
        }
      } catch (e) {
        console.error("INPUTDATA error", e);
      }
    }
  }

  componentDidMount() {
    this.context.showLoader();

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          setTimeout(() => {
            window.location.replace("/?r=" + Math.random())
          }, 1000);
        } else {
          this.context.hideLoader();
        }
      })
      .catch((err) => {
        window.location.replace("/?r=" + Math.random());
      });

    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    clientStorage.setLatestSelectedImages(this.state.images.map((image) => {
      return Object.assign({}, image, {checked: false});
    }));

    this.checkPendingImages();
  }

  checkPendingImages = () => {
    debounce("PhotochooserPage_checkPendingImages", 200, () => {
      this.state.images
        .filter((image) => image.status === imageStatus.pending)
        .forEach((image, index) => this.checkImage(image, index));
    });
  };

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      num_photos: 10,
      show: "gallery",
      tab: "faces",
      neurocamera: 1,
    });
  };

  checkImage = (image) => {
    if (this.imageTasks[image.url]) {
      return;
    }

    image.status = imageStatus.valid;
    this.setState({images: this.state.images.slice()});

    this.imageTasks[image.url] = Promise.resolve(image);
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();

    data.photos
      .map((image) => mapSelectedImageItem(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.url === image.url) === -1) {
          image.status = imageStatus.pending;
          image.number = ++this.imagesNumber;
          stateImages.push(image);

          webviewAnalyticsEvent("photo_selected", [
            "",
            "photo_chooser",
            clientStorage.getSelectedPhotosAmount() + 1,
            image.number,
          ]);
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });
  };

  handleBrowserFileSelected(images) {
    this.context.showLoader();

    const stateImages = this.state.images.slice();

    Promise.all([...images].map((image) => uploadHandler(image).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            image.status = imageStatus.pending;
            image.number = ++this.imagesNumber;
            stateImages.push(image);
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        }, this.context.hideLoader);
      })
      .catch(console.error);
  }

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.url === image.url);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    this.handleRemoveImage(image);
  };

  handleProcessClick = () => {
    this.handleProcess();
  };

  handleProcess = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid);

    if (images.isEmpty()) {
      return;
    }

    this.props.history.push(generatePath(routes.PROCESSING), {
      files: images,
      seeds: ("" + this.state.seeds)
        .split(",")
        .map((item) => item.trim()),
      strengths: this.state.strengths
        .split(",")
        .map((item) => item.trim()),
      prompt: this.state.promptText,
      negativePrompt: this.state.negativePromptText,
      faceTemplateChain: this.state.faceTemplateChain.trim(),
      ctrlnetWeight: this.state.ctrlnetWeight.trim(),
      num_steps: this.state.num_steps.trim(),
    });
  };

  handleAddPhotosClick = () => {
    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  handlePredefinedItemSelected = (id) => {
    const predefined = predefineds.find((item) => item.id === id);

    this.setState({
      predefinedId: predefined.id,
      seeds: predefined.seeds,
      promptText: predefined.prompt,
    });
  };

  handleRandomSeedsButtonClick = () => {
    const seeds = [];
    while (seeds.length < 10) {
      const seed = Math.ceil(Math.random() * 100_000);
      if (seeds.includes(seed)) {
        continue;
      }

      seeds.push(seed);
    }

    this.setState({seeds: seeds.join(", ")});
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    return <React.Fragment>
      <Container fluid="md" className="mt-2 mb-2">

        <Stack>
          <Form.Group className="mb-3" controlId="form.predefined">
            <Form.Label>Predefined</Form.Label>
            <Form.Select value={this.state.predefinedId} onChange={(e) => this.handlePredefinedItemSelected(e.target.value)}>
              {predefineds.map((item) => <option key={item.id} value={item.id}>
                SEEDS: {item.seeds}, PROMPT: {item.prompt}
              </option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.seeds">
            <Form.Label>
              Seeds (comma separated)
              <Button variant="link" size="sm" onClick={this.handleRandomSeedsButtonClick}>Random</Button>
            </Form.Label>
            <Form.Control as="input" type="text" value={this.state.seeds} onChange={(e) => this.setState({seeds: e.target.value})} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.seeds">
            <Form.Label>Strengths (comma separated)</Form.Label>
            <Form.Control as="input" type="text" value={this.state.strengths} onChange={(e) => this.setState({strengths: e.target.value})} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.prompt">
            <Form.Label>Prompt</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={this.state.promptText}
              onChange={(e) => this.setState({promptText: e.target.value})}
            />
          </Form.Group>
          <Form.Group controlId="form.negativePrompt">
            <Form.Label>Negative prompt</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={this.state.negativePromptText}
              onChange={(e) => this.setState({negativePromptText: e.target.value})}
            />
          </Form.Group>

          <hr />

          {faceTemplateChains.map((item) => <Form.Group key={item.value}>
            <Form.Check
              name="faceTemplateChain"
              type="radio"
              label={item.label}
              value={item.value}
              checked={this.state.faceTemplateChain === item.value}
              onChange={(e) => e.target.checked && this.setState({faceTemplateChain: e.target.value})}
            />
          </Form.Group>)}
          <Form.Group>
            <Form.Control
              name="faceTemplateChain"
              value={this.state.faceTemplateChain}
              onChange={(e) => this.setState({faceTemplateChain: e.target.value})}
            />
          </Form.Group>

          <hr />

          <Form.Group>
            <Form.Label>CtrlNet weight</Form.Label>
            <Form.Control
              value={this.state.ctrlnetWeight}
              disabled
              onChange={(e) => this.setState({ctrlnetWeight: e.target.value})}
            />
          </Form.Group>
        </Stack>

        <hr />

        <div>
          <Button onClick={this.handleAddPhotosClick} size="lg">
            {i18n.t("button__select_photos")}
          </Button>
          &nbsp;
          <Button onClick={this.handleProcessClick} hidden={validImagesCount < 1} variant="success" size="lg">
            {i18n.t("button__proceed")}
          </Button>
        </div>

        <Row className="mt-3" hidden={this.state.images.isEmpty()}>
          {this.state.images.map((image) => <Col key={image.url} xs={6} md={3} lg={2}>
            <Figure onClick={() => this.handleImageClick(image)} style={{margin: 0}}>
              <Figure.Image rounded src={image.url} />
            </Figure>
          </Col>)}
        </Row>

      </Container>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        multiple
        ref={(ref) => this.fileFieldRef = ref}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFileSelected(e.target.files)}
      />

    </React.Fragment>;
  }
}

PhotochooserPage.contextType = AppContext;
